import { IMG_BASE } from '@nm-namshi-frontend/core/config';

import accountHome from './icons/home.svg';
import shoppingBag from './icons/bag.svg';
import heart from './icons/heart.svg';
import heartFilled from './icons/heartFilled.svg';
import person from './icons/person.svg';
import share from './icons/share.svg';
import plus from './icons/plus.svg';
import minus from './icons/minus.svg';
import sort from './icons/sort.svg';
import filter from './icons/filter.svg';
import arrowBack from './icons/arrowBack.svg';
import search from './icons/search.svg';
import twitter from './icons/twitter.svg';
import facebook from './icons/facebook.svg';
import link from './icons/link.svg';
import externalLink from './icons/externalLink.svg';
import close from './icons/close.svg';
import closeFilled from './icons/close-filled.svg';
import check from './icons/check.svg';
import remove from './icons/remove.svg';
import namshiNonReturnable from './icons/namshiNonReturnable.svg';
import verifiedTick from './icons/verifiedTick.svg';
import scrollTop from './icons/scroll-top.svg';
// Need to add tamara in image.ts with cdn
import Tamara from './icons/tamara.svg';
import verified from './icons/verified.svg';
import credits from './icons/payment-methods/credits.svg';
import cod from './icons/payment-methods/cod.svg';
import codGreen from './icons/codGreen.svg';
import applePay from './icons/payment-method-applepay.svg';
import fastTruck from './icons/fast-truck.svg';
import orderSuccess from './icons/orderSuccess.svg';
import editDark from './icons/edit_dark.svg';
import lockNew from './icons/lock_new.svg';
import lockFilled from './icons/lock_filled.svg';
import globeDark from './icons/globe_dark.svg';
import roundedCheck from './icons/rounded-check.svg';
import savedCards from './icons/saved-cards.svg';
import visa from './icons/payment-method-cc-visa.svg';
import mastercard from './icons/payment-method-cc-mastercard.svg';
import mada from './icons/payment-method-cc-mada.svg';
import amex from './icons/payment-method-cc-amex.svg';
import chevronDownBold from './icons/chevronDownBold.svg';
import arrowUpGreen from './icons/arrow-up-green.svg';
import arrowDownRed from './icons/arrow-down-red.svg';
import chevronForwardBold from './icons/chevron-forword-bold.svg';
import chevronBackwardBold from './icons/chevron-backward-bold.svg';
import instagramFilled from './icons/instagram-filled.svg';
import twitterFilled from './icons/twitter-filled.svg';
import facebookFilled from './icons/facebook-filled.svg';
import linkedinFilled from './icons/linkedin-filled.svg';
import visacardLogo from './icons/visacard-logo.svg';
import mastercardLogo from './icons/mastercard-logo.svg';
import snapchatFilled from './icons/snapchat-filled.svg';
import youtubeFilled from './icons/youtube-filled.svg';
import pinterestFilled from './icons/pinterest-filled.svg';
import restOfTheWorld from './icons/restOfTheWorld.svg';
import discountIcon from './icons/discount.svg';
import discountBG from './icons/discountBG.svg';
import noOrder from './icons/no-order.svg';
import wallet from './icons/wallet.svg';
import redWarning from './icons/red-warning.svg';
import warningTriangle from './icons/warning-triangle.svg';
import chevronUpBold from './icons/chevronUpBold.svg';
import timer from './icons/timer.svg';
import infoCircle from './icons/infoCircle.svg';
import threeDotsVertical from './icons/three-dots-vertical.svg';
import rocket from './icons/rocket.svg';
import global from './icons/global.svg';
import star from './icons/star.svg';
import rocketPlain from './icons/rocketPlain.svg';
import dotsHorizontal from './icons/dots-horizontal.svg';
import box from './icons/box.svg';
import camera from './icons/Camera.svg';
import mySize from './icons/my-size.svg';
// design system icon set - please check with cli or isuru before adding
import dsCheck from './icons/icon-set/check.svg';
import dsExclamationCircle from './icons/icon-set/exclamation-circle.svg';
import dsInfoSquare from './icons/icon-set/info-square.svg';
import dsWarningTriangle from './icons/icon-set/warning-triangle.svg';
// tabbar - these are slightly different in positioning and thickness to the regular icon set
import home from './icons/icon-set/tabbar/home.svg';
import home_selected from './icons/icon-set/tabbar/home-selected.svg';
import category from './icons/icon-set/tabbar/categories.svg';
import category_selected from './icons/icon-set/tabbar/categories-selected.svg';
import cart from './icons/icon-set/tabbar/bag.svg';
import cart_selected from './icons/icon-set/tabbar/bag-selected.svg';
import wishlist from './icons/icon-set/tabbar/wishlist.svg';
import wishlist_selected from './icons/icon-set/tabbar/wishlist-selected.svg';
import account from './icons/icon-set/tabbar/account.svg';
import account_selected from './icons/icon-set/tabbar/account-selected.svg';
import sale from './icons/icon-set/tabbar/sale.svg';
import sale_selected from './icons/icon-set/tabbar/sale-selected.svg';
import beauty from './icons/icon-set/tabbar/beauty.svg';
import beauty_selected from './icons/icon-set/tabbar/beauty-selected.svg';
import sports from './icons/icon-set/tabbar/sports.svg';
import sports_selected from './icons/icon-set/tabbar/sports-selected.svg';
import back_to_school from './icons/icon-set/tabbar/back_to_school.svg';
import back_to_school_selected from './icons/icon-set/tabbar/back_to_school-selected.svg';
import premium from './icons/icon-set/tabbar/premium.svg';
import premium_selected from './icons/icon-set/tabbar/premium-selected.svg';
import looks from './icons/icon-set/tabbar/looks.svg';
import playVideo from './icons/play-video.svg';
import diwali from './icons/icon-set/tabbar/diwali.svg';
import diwali_selected from './icons/icon-set/tabbar/diwali-selected.svg';
import els from './icons/icon-set/tabbar/els.svg';
import els_selected from './icons/icon-set/tabbar/els-selected.svg';
import phone from './icons/phone.svg';
import email from './icons/mail.svg';
import singles_day_sale from './icons/icon-set/tabbar/singles_day_sale.svg';
import singles_day_sale_selected from './icons/icon-set/tabbar/singles_day_sale-selected.svg';
import bn_sale from './icons/icon-set/tabbar/bn_sale.svg';
import bn_sale_selected from './icons/icon-set/tabbar/bn_sale-selected.svg';
import friday_sale from './icons/icon-set/tabbar/friday_sale.svg';
import friday_sale_selected from './icons/icon-set/tabbar/friday_sale-selected.svg';
// tabbar icons end
import chevronUp from './icons/chevronUp.svg';
import chevronDown from './icons/chevronDown.svg';

export const ICONS = {
    accountHome,
    shoppingBag,
    heart,
    person,
    heartFilled,
    share,
    plus,
    minus,
    sort,
    filter,
    arrowBack,
    search,
    twitter,
    facebook,
    link,
    externalLink,
    closeFilled,
    close,
    check,
    remove,
    namshiNonReturnable,
    verifiedTick,
    scrollTop,
    Tamara: `${IMG_BASE}/namshi/images/logo-tamara-en-v2.svg`,
    verified,
    credits,
    tabby: `${IMG_BASE}/namshi/images/logo-tabby-v2.svg`,
    cod,
    fastTruck,
    orderSuccess,
    editDark,
    lockNew,
    lockFilled,
    globeDark,
    roundedCheck,
    savedCards,
    visa,
    mastercard,
    mada,
    amex,
    chevronDownBold,
    chevronUpBold,
    arrowUpGreen,
    arrowDownRed,
    chevronForwardBold,
    chevronBackwardBold,
    instagramFilled,
    twitterFilled,
    facebookFilled,
    linkedinFilled,
    applePay,
    visacardLogo,
    mastercardLogo,
    codGreen,
    snapchatFilled,
    youtubeFilled,
    pinterestFilled,
    restOfTheWorld,
    discountIcon,
    discountBG,
    noOrder,
    wallet,
    redWarning,
    warningTriangle,
    timer,
    infoCircle,
    threeDotsVertical,
    rocket,
    global,
    rocketPlain,
    dotsHorizontal,
    box,
    plane: `${IMG_BASE}/namshi/icons/plane.svg`,
    playVideo,
    camera,
    mySize,
    star,
    chevronUp,
    chevronDown,
    //
    arrowForward: `${IMG_BASE}/sivvi/design-system/icons-v2/arrow-forward.svg`,
    bell: `${IMG_BASE}/sivvi/design-system/icons-v2/bell.svg?v1`,
    boxes: `${IMG_BASE}/sivvi/design-system/icons-v2/boxes.svg`,
    caretDown: `${IMG_BASE}/sivvi/design-system/icons-v2/caret-down.svg`,
    checkCircle: `${IMG_BASE}/sivvi/design-system/icons-v2/check-circle.svg`,
    chevronBack: `${IMG_BASE}/sivvi/design-system/icons-v2/chevron-back.svg`,
    chevronForward: `${IMG_BASE}/sivvi/design-system/icons-v2/chevron-forward.svg`,
    rightChevronRounded: `${IMG_BASE}/namshi/icons/right-chevron-rounded.svg`,
    leftChevronRounded: `${IMG_BASE}/namshi/icons/left-chevron-rounded.svg`,
    greenTick: `${IMG_BASE}/namshi/icons/green-tick.svg`,
    closeCircle: `${IMG_BASE}/sivvi/design-system/icons-v2/close-circle.svg`,
    closeLight: `${IMG_BASE}/namshi/icons/close-light1.svg`,
    copy: `${IMG_BASE}/sivvi/design-system/icons-v2/copy.svg`,
    discountTag: `${IMG_BASE}/sivvi/design-system/icons-v2/discount-tag.svg`,
    door: `${IMG_BASE}/sivvi/design-system/icons-v2/door.svg`,
    edit: `${IMG_BASE}/sivvi/design-system/icons-v2/edit.svg`,
    errorCircle: `${IMG_BASE}/sivvi/design-system/icons-v2/error-circle.svg`,
    exchange: `${IMG_BASE}/sivvi/design-system/icons-v2/exchange.svg`,
    eye: `${IMG_BASE}/sivvi/design-system/icons-v2/eye.svg`,
    eyeSlash: `${IMG_BASE}/sivvi/design-system/icons-v2/eye-slash.svg`,
    flashCircle: `${IMG_BASE}/sivvi/design-system/icons-v2/flash-circle.svg?v1`,
    globe: `${IMG_BASE}/sivvi/design-system/icons-v2/globe.svg`,
    grid: `${IMG_BASE}/sivvi/design-system/icons-v2/grid.svg`,
    gridFilled: `${IMG_BASE}/sivvi/design-system/icons-v2/grid-filled.svg`,
    heartArrow: `${IMG_BASE}/sivvi/design-system/icons-v2/heart-arrow.svg`,
    list: `${IMG_BASE}/sivvi/design-system/icons-v2/list.svg`,
    listFilled: `${IMG_BASE}/sivvi/design-system/icons-v2/list-filled.svg`,
    lock: `${IMG_BASE}/sivvi/design-system/icons-v2/lock.svg`,
    mail: `${IMG_BASE}/sivvi/design-system/icons-v2/mail.svg`,
    notReturnable: `${IMG_BASE}/namshi/icons/not-returnable.svg`,
    plusCircle: `${IMG_BASE}/sivvi/design-system/icons-v2/plus-circle.svg`,
    minusCircle: `${IMG_BASE}/sivvi/design-system/icons-v2/minus-circle.svg`,
    questionCircle: `${IMG_BASE}/sivvi/design-system/icons-v2/question-circle.svg`,
    tiktok: `${IMG_BASE}/sivvi/design-system/icons-v2/tiktok.svg`,
    trash: `${IMG_BASE}/namshi/icons/trash-1.svg`,
    whatsapp: `${IMG_BASE}/sivvi/design-system/icons-v2/whatsapp.svg?v1`,
    unverifiedPhoneNo: `${IMG_BASE}/namshi/icons/unverified-phone-number.svg`,
    unverifiedPhoneNo2: `${IMG_BASE}/namshi/icons/unverified-phone-number2.svg`,
    unverifiedDocuments: `${IMG_BASE}/namshi/icons/unverified-documents.svg`,
    unverifiedDocuments2: `${IMG_BASE}/namshi/icons/unverified-documents2.svg`,
    deliveryParcel: `${IMG_BASE}/namshi/icons/carbon-delivery-parcel.svg`,
    error: `${IMG_BASE}/namshi/icons/error.svg`,
    court: `${IMG_BASE}/namshi/icons/court.svg`,
    notFilledCircle: `${IMG_BASE}/namshi/icons/not-filled-circle.svg`,
    cube: `${IMG_BASE}/namshi/icons/cube.svg`,
    receipt: `${IMG_BASE}/namshi/icons/receipt.svg`,
    security: `${IMG_BASE}/namshi/icons/security.svg`,
    size: `${IMG_BASE}/namshi/icons/size.svg`,
    personal: `${IMG_BASE}/namshi/icons/personal.svg`,
    arrowOutward: `${IMG_BASE}/namshi/icons/arrow-outward.svg`,
    doubleChecks: `${IMG_BASE}/namshi/icons/double-checks.svg`,
    triangleError: `${IMG_BASE}/namshi/icons/error-triangle.svg`,
    qucikAddToBag: `${IMG_BASE}/namshi/icons/quick-add-to-bag.svg`,
    couponTag: `${IMG_BASE}/namshi/icons/coupon-tag.svg`,

    // design system icon set - please check with cli or isuru before adding
    dsCheck,
    dsExclamationCircle,
    dsInfoSquare,
    dsWarningTriangle,

    // tabbar
    home,
    home_selected,
    category,
    category_selected,
    cart,
    cart_selected,
    wishlist,
    wishlist_selected,
    account,
    account_selected,
    looks,
    sale,
    sale_selected,
    beauty,
    beauty_selected,
    sports,
    sports_selected,
    'back-to-school': back_to_school,
    'back-to-school_selected': back_to_school_selected,
    premium,
    premium_selected,
    diwali,
    diwali_selected,
    els,
    els_selected,
    phone,
    email,
    'singles-day-sale': singles_day_sale,
    'singles-day-sale_selected': singles_day_sale_selected,
    'bn-sale': bn_sale,
    'bn-sale_selected': bn_sale_selected,
    'friday-sale': friday_sale,
    'friday-sale_selected': friday_sale_selected,
};

export type TConfigIcons = keyof typeof ICONS;

export const DEFAULT_ICON_SIZE = 21;
export const AR_FLIP_ICONS: TConfigIcons[] = [
    'arrowBack',
    'arrowForward',
    'chevronBack',
    'chevronForward',
    'chevronForwardBold',
    'plane',
    'global',
    'couponTag',
];

export const ICONS_AR_TRANSLATIONS = {
    Tamara: `${IMG_BASE}/namshi/images/logo-tamara-ar-v2.svg`,
};

export const ICONS_AR = { ...ICONS, ...ICONS_AR_TRANSLATIONS };
