import { useState, useEffect } from 'react';

import { isServer } from '../utils/helper';

const getWindowDimensions = () => {
    if (isServer()) {
        return {
            width: 0,
            height: 0,
        };
    }

    const { innerHeight: height, innerWidth: width } = global;
    return {
        width,
        height,
    };
};

/**
 * Custom hook to help rendering components based on viewport width/height
 */
const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        global.addEventListener('resize', handleResize);
        return () => global.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

export default useWindowDimensions;
